/**
 * Created by vaibhav on 2/4/18
 */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import Link from "gatsby-link"
import NoSSR from "react-no-ssr"
import BackgroundSlider from "react-background-slider"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import PageWrapper from "../../layouts/PageWrapper"
import TransportImg1 from "../../img/transport/img1.png"
import TransportImg2 from "../../img/transport/img2.png"
import TransportImg3 from "../../img/transport/img3.png"
import HomeLogo from "../../components/HomeLogo"

class CorePage extends Component {
  componentDidMount() {
    window.onpageshow = function () {
      const slider = document.getElementById("ReactBackgroundSlider")
      if (slider) {
        slider.style.height = `${
          document.getElementById("master").scrollHeight
        }px`
        slider.style.width = "100vw"
        slider.style.position = "absolute"
      }
    }
    setTimeout(function () {
      const slider = document.getElementById("ReactBackgroundSlider")
      if (slider) {
        slider.style.height = `${
          document.getElementById("master").scrollHeight
        }px`
        slider.style.width = "100vw"
        slider.style.position = "absolute"
      }
    }, 500)
  }

  render() {
    return (
      <PageWrapper>
        <section className="hero is-fullheight" id="master">
          <div>
            <div id="core_slider-desktop">
              <NoSSR>
                <BackgroundSlider
                  images={[TransportImg1, TransportImg2, TransportImg3]}
                  duration={5}
                  transition={3}
                />
              </NoSSR>
            </div>
            <Helmet>
              <title>Core Businesses - E.A. Technique</title>
            </Helmet>
            <section className="hero is-small">
              <div className="hero-body">
                <div className="container">
                  <div className="columns">
                    <div className="column">
                      <HomeLogo />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="section is-paddingless" id="core_page">
              <div id="core_slider-mobile">
                <NoSSR>
                  <BackgroundSlider
                    images={[TransportImg1, TransportImg2, TransportImg3]}
                    duration={5}
                    transition={3}
                  />
                </NoSSR>
              </div>
              <div className="container">
                <Tabs>
                  <div className="core columns is-multiline">
                    <div className="column is-6-desktop is-12-tablet">
                      <div
                        className="box"
                        id="core-box"
                        style={{
                          opacity: 0.9,
                        }}
                      >
                        <TabPanel>
                          <h3 className="title is-primary is-3">
                            Marine Transportation and Offshore Storage of Oil &
                            Gas
                          </h3>
                          <p style={{ textAlign: "justify", fontSize: "14px" }}>
                            Our product tankers are used to transport refined petroleum products from oil refineries to end-users or to another refinery for further processing known as Clean Petroleum Products (“CPP”) e.g., kerosene (jet fuel), diesel and petrol (RON95 & RON97) and also Dirty Petroleum Products (‘DPP”) such as fuel oil and crude oil.
                            <br />
                            <br />
                            FSU/FSO are typically used to support production platforms as an offshore Oil & Gas storage facility at brown fields.
                            <br />
                            <br />
                            We also operate Offshore Supply Vessels (“OSV”), namely fast crew boats, which are primarily used to transport personnel/light cargoes between shore and platform, platform and platform and other offshore facilities.
                          </p>
                        </TabPanel>
                        <TabPanel />
                        <TabPanel />
                      </div>
                    </div>
                    <div className="column is-3-desktop is-9-tablet is-offset-3 core_page-tabs-container">
                      <TabList className="react-tabs__tab-list-primary core_page-tabs-list orange">
                        <Link to="/core">
                          <Tab>
                            Marine Transportation and Offshore Storage of Oil &
                            Gas
                          </Tab>
                        </Link>
                        <Link to="/core/engineer">
                          <Tab>Marine Engineering Service</Tab>
                        </Link>
                        <Link to="/core/port">
                          <Tab>Provision of Port Marine Services</Tab>
                        </Link>
                      </TabList>
                    </div>
                  </div>
                </Tabs>
              </div>
            </section>
          </div>
        </section>
      </PageWrapper>
    )
  }
}

CorePage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default CorePage
